<template>
  <div class="bullcoin-svg">
    <svg
      viewBox="0 0 11 15"
      xmlns="http://www.w3.org/2000/svg"
      fill="currentColor"
    >
      <path
        d="M5.09013 0C5.5752 0 5.96842 0.393225 5.96842 0.878293V1.84418H6.36947C8.38592 1.84418 10.1805 2.98918 10.1805 5.04229V5.21996C10.1805 6.20703 9.77726 6.93746 9.21266 7.39151L9.11183 7.47048L9.21266 7.56918C9.77726 8.02324 10.1805 8.75367 10.1805 9.74074V9.93815C10.1805 11.9913 8.38592 13.1363 6.36947 13.1363H5.96842V14.1217C5.96842 14.6068 5.5752 15 5.09013 15C4.60506 15 4.21184 14.6068 4.21184 14.1217V13.1363H0.988056C0.443617 13.1363 0 12.7019 0 12.1887C0 11.6754 0.443617 11.2213 0.988056 11.2213H6.38964C7.53901 11.2213 8.04312 10.5501 8.04312 9.93815V9.74074C8.04312 9.12875 7.53901 8.43781 6.38964 8.43781H5.15961C4.63533 8.43781 4.19172 8.0035 4.19172 7.47048C4.19172 6.9572 4.63533 6.52289 5.15961 6.52289H6.38964C7.53901 6.52289 8.04312 5.83194 8.04312 5.21996V5.04229C8.04312 4.4303 7.53901 3.7591 6.38964 3.7591H0.988056C0.443617 3.7591 0 3.30505 0 2.79177C0 2.27849 0.443617 1.84418 0.988056 1.84418H4.21184V0.878293C4.21184 0.393225 4.60506 0 5.09013 0Z"
      />
    </svg>
  </div>
</template>

<style lang="scss" scoped>
.bullcoin-svg {
  display: inline-flex !important;
  align-items: center !important;
  flex-direction: row;
  justify-content: center;
  position: relative;
  top: 0.125em;
  height: 1em;
  svg {
    height: 1em;
  }
}
</style>
